// src/components/Dashboard.tsx
import React from 'react';
import { Button } from '@mui/material';
import { signOut } from 'firebase/auth';
import { auth } from '../../../auth/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import './dashboard.css'

const Dashboard: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <section id="home" className="home">
                <div className="home-content">
                    <h2>Olá! Boas-vindas à Tecnolokid</h2>
                    <p>Ensino de Tecnologia, Inovação e Sustentabilidade, alinhados à BNCC.</p>
                </div>
            </section>
            {/* <section id="about" className="about">
                <h2>Sobre nós</h2>
                <p>Inspiramos crianças e adolescentes, por meio do ensino de tecnologia e inovação, para que possam transformar a sociedade e construir um futuro melhor.</p>
            </section> */}
            <section id="solutions" className="solutions">
                <h2>Comece por aqui!</h2>
                <div className="solutions-container">
                    <div className="solution">
                        <h3>O que são as ODS?</h3>
                        <p>As ODS (Objetivos de Desenvolvimento Sustentável) são 17 metas globais estabelecidas pela ONU em 2015 para serem alcançadas até 2030. Elas visam eliminar a pobreza, proteger o planeta e garantir prosperidade para todos.</p>
                    </div>
                    <div className="solution">
                        <h3>BNCC versão atualizada</h3>
                        <p>Nossas aulas são alinhadas as habilidades da BNCC, agora também </p>
                    </div>
                    <div className="solution">
                        <h3>Para Famílias</h3>
                        <p>Experiências educacionais personalizadas para crianças e adolescentes.</p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Dashboard;

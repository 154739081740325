import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../auth/AuthContext';
import { Button, TextField, Box, Typography, Container, CssBaseline, ThemeProvider, Checkbox, FormControlLabel, createTheme } from '@mui/material';
import logo from "../../../assets/logo_azul.png";
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../auth/firebaseConfig';

const Copyright: React.FC<{ sx?: object }> = (props) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <a color="inherit" href="https://tecnolokid.com.br/">
                Tecnolokid
            </a>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};

const defaultTheme = createTheme();

const SignIn: React.FC = () => {
    const navigate = useNavigate();
    const { setCurrentUser } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            setCurrentUser(userCredential.user);
            navigate('/table'); // Redirecionar para o dashboard após login bem-sucedido
        } catch (error) {
            console.error('Login Failed:', error);
            alert("Usuário ou senha inválidos, tente novamente.");
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs" sx={{ background: "#ebf1ff", borderRadius: "10px", padding: 2, marginTop: 0 }}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src={logo} width={"200px"} alt="Logo" />
                    <Typography component="h1" variant="h5" sx={{ marginTop: 1 }}>
                        Acessar
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Endereço de E-mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Senha"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Lembre de mim"
                        />
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            Acessar
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 2, mb: 2 }} />
            </Container>
        </ThemeProvider>
    );
};

export default SignIn;

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import SignIn from './components/View/LoginView/login';
import Dashboard from './components/View/Dashboard/dashboard';
import { AuthProvider } from './auth/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import DataTable from './components/View/DataTable/DataTable';
import Header from './components/Global/Header/header';
import Footer from './components/Global/Footer/footer';
import Contact from './components/View/Contato/contact';
import Player from './components/View/Player/player';
import './index.css'; // Importando o CSS global
import { Box, Container } from '@mui/material';

const App: React.FC = () => {
  const location = useLocation();
  const isSignInPage = location.pathname === '/signin';

  return (
    <>
      {!isSignInPage && <Header />}
      <Box>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/table"
            element={
              <ProtectedRoute>
                <DataTable />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contact"
            element={
              <ProtectedRoute>
                <Contact />
              </ProtectedRoute>
            }
          />
          <Route
            path="/player"
            element={
              <ProtectedRoute>
                <Player />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Box>
      {!isSignInPage && <Footer />}
    </>
  );
};

ReactDOM.render(
  <AuthProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AuthProvider>,
  document.getElementById('root')
);

// src/components/View/Contact/Contact.tsx
import React from 'react';
import './contact.css'

const Contact: React.FC = () => {
    return (
        <section id="contact" className="contact">
            <h2>Contato</h2>
            <p>Para mais informações sobre nossas soluções, entre em contato.</p>
            <p>Email: contato@tecnolokid.com.br</p>
            <p>Telefone: (27) 99601-0110</p>
        </section>
    );
};

export default Contact;

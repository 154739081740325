import React, { useRef, useEffect } from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { styled } from '@mui/material/styles';

const VideoPlayer = styled('video')(({ theme }) => ({
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
}));

const InfoBox = styled(Box)(({ theme }) => ({
    background: '#f5f5f5',
    borderRadius: '10px',
    padding: theme.spacing(2),
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(2),
}));

const ResourceBox = styled(Box)(({ theme }) => ({
    background: '#2096f3',
    borderRadius: '10px',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
}));

const Player: React.FC = () => {
    const location = useLocation();
    const videoUrl = new URLSearchParams(location.search).get('videoUrl');
    const videoTitle = new URLSearchParams(location.search).get('title');
    const videoDescription = new URLSearchParams(location.search).get('description');
    const videoDescription2 = new URLSearchParams(location.search).get('description2');
    const bncc = new URLSearchParams(location.search).get('bncc');

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const playerRef = useRef<videojs.VideoJsPlayer | null>(null);

    useEffect(() => {
        if (videoRef.current && !playerRef.current) {
            playerRef.current = videojs(videoRef.current, {
                controls: true,
                preload: 'auto',
                controlBar: {
                    children: [
                        'playToggle',
                        'volumePanel',
                        'currentTimeDisplay',
                        'timeDivider',
                        'durationDisplay',
                        'progressControl',
                        'fullscreenToggle',
                    ],
                },
            });
        }

        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
                playerRef.current = null;
            }
        };
    }, [videoUrl]);

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={8}>
                    <Box sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <VideoPlayer
                            ref={videoRef}
                            className="video-js vjs-default-skin"
                            src={videoUrl || ''}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                borderRadius: '10px',
                            }}
                        />
                    </Box>
                    <Typography variant="caption" display="block" textAlign="center" mt={1}>
                        Proibida a distribuição sem autorização. Direitos autorais reservados.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <InfoBox>
                            <Typography variant="h6" gutterBottom>
                                {videoTitle || 'Título do Vídeo'}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Habilidades BNCC: {bncc || 'Habilidade BNCC'}
                            </Typography>
                        </InfoBox>
                        <InfoBox>
                            <Typography variant="h6" gutterBottom>
                                Atividade Prática 1
                            </Typography>
                            <Typography variant="body2">
                                {videoDescription || 'Descrição do Vídeo'}
                            </Typography>
                        </InfoBox>
                        <InfoBox>
                            <Typography variant="h6" gutterBottom>
                                Atividade Prática 2
                            </Typography>
                            <Typography variant="body2">
                                {videoDescription2 || 'Descrição do Vídeo'}
                            </Typography>
                        </InfoBox>
                        <ResourceBox>
                            <Typography variant="h6" gutterBottom>
                                Recursos para a Aula
                            </Typography>
                            <Button variant="contained" sx={{ backgroundColor: '#fafafa', color: '#2096f3', mt: 1 }}>
                                Baixar
                            </Button>
                        </ResourceBox>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Player;

// src/services/authService.ts

import axios from 'axios';

const API_URL = 'https://aulastech.abcjogos.com.br/api/auth'; // URL do backend

const login = async (email: string, password: string) => {
    try {
        const response = await axios.post(`${API_URL}/login`, { email, password });
        return response.data;
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};

const verifyToken = async (token: string) => {
    try {
        const response = await axios.post(`${API_URL}/verify`, { token });
        return response.data;
    } catch (error) {
        throw new Error('Token verification failed');
    }
};

export { login, verifyToken };
